import { ContextMenu, MenuItem } from "react-contextmenu";
import React from "react";

import API from "../api";
import cogoToast from "cogo-toast";

export const onPopover = async (domain, symbol) => {
  window.open(API.getStockPageLink(`${domain}.com`, symbol), "_blank");
};

export const registerAlert = async (symbol, type, high = 0, low = 0) => {
  const dic = {
    trade: "Trade",
    uv: "Unusual volume",
    vwap: "vWAPDist",
    price: "Price",
    "hi/lo": "Hi/low",
  };
  try {
    const result = await API.addAlert({
      category: symbol,
      rate: 0,
      high,
      low,
      type,
    });
    if (result && result.success) {
      cogoToast.success(`${dic[type]} alert added for ${symbol}`);
    } else if (result && result.error) {
      throw result.error;
    }
  } catch (e) {
    if (e === "SequelizeUniqueConstraintError: Validation error") {
      cogoToast.error(`${dic[type]} alert for ${symbol} is already registered!`);
    } else {
      cogoToast.error(`Failed to register ${dic[type]} alert for ${symbol}`);
    }
  }
};

export const round = (value, decimals) => {
  const num = parseFloat(value);
  if (isNaN(num)) {
    return "__";
  } else {
    return num.toFixed(decimals);
  }
};

export const getBrowserType = () => {
  // Opera 8.0+
  var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || (typeof window.safari !== "undefined" && window.safari.pushNotification));

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

  return {
    isOpera,
    isFirefox,
    isSafari,
    isIE,
    isEdge,
    isChrome,
    isEdgeChromium,
    isBlink,
  };
};
