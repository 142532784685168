import React from "react";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

import { getDefaultLayoutForDevice } from "../../dashboard/dashboardReducer";

import { DEFAULT_DASHBOARD_LAYOUT_CONFIG, LAYOUT_BREAKPOINT_MEDIA_QUERIES } from "../../constants";

import styles from "./index.module.scss";

function LayoutButton(props) {
  const { layout, setLayoutActive, resetLayout, toggleLayoutLock, triggerLayoutForceUpdate } = props;

  const layoutInfo = layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG;

  return (
    <div className="d-flex pr-1 pr-sm-3">
      {/* <div className={`bar-icon showWidget`} style={{background: 'unset'}} onClick={() => {
        toggleLayoutLocked();
      }}>
        <i className={`fa ${layoutLocked ? "fa-lock" : "fa-unlock"}`} />
      </div>
      <div className={`bar-icon ${layoutLocked ? 'hideWidget' : 'showWidget'}`} style={{background: 'unset'}} onClick={() => {
        if (!layoutLocked) {
          updateLayoutConfig(null)
          updateLayoutDeviceMedia(null)
        }
      }}>
        <i className={`fa fa-th-large`} />
      </div> */}
      <Dropdown varaint="btn btn-outline-secondary" className="d-flex align-items-center">
        <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret py-0 px-2">
          <div className={`bar-icon showWidget`} style={{ background: "unset" }}>
            <i className={`fa fa-th-large`} />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {layoutInfo.map((config) => {
            return (
              <Dropdown.Item
                key={config.key}
                className="d-flex justify-content-between"
                onClick={() => setLayoutActive && setLayoutActive(config.key)}
              >
                <span style={{ color: config.active ? "#F6C548" : "white" }}>{config.label}</span>
                <span className={styles["layout-menu-item-btnset"]}>
                  {config.locked ? (
                    <i
                      className="mdi mdi-lock"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleLayoutLock && toggleLayoutLock(config.key);
                      }}
                    />
                  ) : (
                    <>
                      <i
                        className="fa fa-unlock"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleLayoutLock && toggleLayoutLock(config.key);
                        }}
                      />
                      <i
                        className="fa fa-repeat"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          resetLayout && resetLayout(config.key);
                          triggerLayoutForceUpdate && triggerLayoutForceUpdate();
                        }}
                      />
                    </>
                  )}
                </span>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

LayoutButton.propTypes = {
  layout: PropTypes.array.isRequired,
  setLayoutActive: PropTypes.func,
  resetLayout: PropTypes.func,
  toggleLayoutLock: PropTypes.func,
  triggerLayoutForceUpdate: PropTypes.func,
};

export default LayoutButton;
