import React from "react";
import { Menu, Item } from "react-contexify";
import { connect } from "react-redux";
import { ALERT_CONTEXT_MENU_ID } from "../../constants";
import { registerAlert } from "../../shared/helper";
import "./style.scss";

class AlertPopup extends React.Component {
  async onClickMenuItem(type) {
    const { discoverAlerySelected, widget } = this.props;
    const { symbol, vWAPDist } = discoverAlerySelected[widget];

    try {
      await registerAlert(symbol, type, vWAPDist, vWAPDist);
    } catch (e) {}
  }

  render() {
    const { widget } = this.props;
    return (
      <Menu id={`${ALERT_CONTEXT_MENU_ID}-${widget}`} className="p-0" theme={"dark"}>
        <div className="context-menu-style p-0">
          <Item data={{}} onClick={() => this.onClickMenuItem("price")}>
            <div className="row align-items-center mt-1">
              <span className="medium white-no-wrap bar-txt">Price</span>
            </div>
          </Item>
          <Item data={{}} onClick={() => this.onClickMenuItem("vwap")}>
            <div className="row align-items-center mt-1">
              <span className="medium white-no-wrap bar-txt">VWAP</span>
            </div>
          </Item>
          <Item data={{}} onClick={() => this.onClickMenuItem("uv")}>
            <div className="row align-items-center mt-1">
              <span className="medium white-no-wrap bar-txt">UVol</span>
            </div>
          </Item>
          <Item data={{}} onClick={() => this.onClickMenuItem("hi/lo")}>
            <div className="row align-items-center mt-1">
              <span className="medium white-no-wrap bar-txt">Hi/Lo</span>
            </div>
          </Item>
        </div>
      </Menu>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state, props) => ({
  discoverAlerySelected: state.discovery.discoverAlerySelected,
  ...props,
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertPopup);
