import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Step1 from "./account";
import Step2 from "./profile";
import Step3 from "./payment";
import Step4 from "./subscription";
import Step5 from "./admin";

import { hasSubscription, isActiveSubscription } from "../../constants";

import "./index.scss";

export class UserAccount extends Component {
  constructor() {
    super();

    this.state = {
      currentTab: 0,
    };
  }

  steps = [
    { name: "Account", component: <Step1 /> },
    { name: "Profile", component: <Step2 /> },
    { name: "Payment", component: <Step3 /> },
    { name: "Plan", component: <Step4 /> },
    { name: "Admin", component: <Step5 />, role: "admin" },
  ];

  componentDidMount() {
    const { tab } = this.props.match.params;
    let currentTab = 0;
    if (tab === "profile") {
      currentTab = 1;
    } else if (tab === "payment") {
      currentTab = 2;
    } else if (tab === "plan") {
      currentTab = 3;
    } else if (tab === "admin") {
      currentTab = 4;
    }
    this.setState({
      currentTab,
    });
  }

  render() {
    const { user } = this.props;

    const steps = this.steps.filter((item) => {
      if (!item.role) return true;
      return user?.profile?.role === item.role;
    });

    const currentTab =
      hasSubscription(user.subscription) || isActiveSubscription(user.subscription) ? this.state.currentTab : 3;

    return (
      <div className="profile-content container-md mt-1 mt-md-5 p-1 p-md-4">
        {!(hasSubscription(user.subscription) || isActiveSubscription(user.subscription)) || (
          <ul className="tab-headers">
            {steps.map((step, index) => {
              return (
                <li
                  key={index + ""}
                  className={`tab-item ${index === currentTab ? "active" : ""}`}
                  onClick={() => {
                    this.setState({
                      currentTab: index,
                    });
                  }}
                >
                  {step.name}
                </li>
              );
            })}
          </ul>
        )}
        <div className="p-1">{steps[currentTab].component}</div>
      </div>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccount));
