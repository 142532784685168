import { SortDirection } from "react-virtualized";

export const PRICE_MIN = 0;
export const PRICE_MAX = 5000;

export const AVG_VOL_MIN = 0;
export const AVG_VOL_MAX = 200000000; // Max 200M, unit is K

export const FLOW_MINUTE_VOL_MIN = 0;
export const FLOW_MINUTE_VOL_MAX = 1000000; // Max 1M, unit is K

export const FLOAT_MIN = 0;
export const FLOAT_MAX = 100000000000; // Max 100B, unit is B

export const ATR_MIN = 0;
export const ATR_MAX = 1000; // Max 1000 %

export const GAP_MIN = 0;
export const GAP_MAX = 1000; // Max 1000 %

export const COUNT_MIN = 0;
export const COUNT_MAX = 1000;

export const MARKET_CAP_MIN = 0;
export const MARKET_CAP_MAX = 10000000000000; // 10 Trillion

export const PRICE_RANGE = [
  { value: PRICE_MIN, step: 1 }, // acts as min value
  { value: 10, step: 10 },
  { value: 100, step: 100 },
  { value: 1000, step: 1000 }, // acts as max value
  { value: PRICE_MAX },
];

export const VOLUME_RANGE = [
  { value: AVG_VOL_MIN, step: 10000 }, // acts as min value
  { value: 100000, step: 100000 },
  { value: 500000, step: 500000 },
  { value: 1000000, step: 1000000 }, // acts as max value
  { value: 5000000, step: 5000000 },
  { value: 10000000, step: 10000000 },
  { value: AVG_VOL_MAX },
];

export const FLOW_MINUTE_VOLUME_RANGE = [
  { value: FLOW_MINUTE_VOL_MIN, step: 100 }, // acts as min value
  { value: 1000, step: 500 },
  { value: 10000, step: 10000 },
  { value: 100000, step: 100000 },
  { value: FLOW_MINUTE_VOL_MAX },
];

export const FLOAT_RANGE = [
  { value: FLOAT_MIN, step: 1000000 }, // acts as min value
  { value: 1000000, step: 4000000 },
  { value: 5000000, step: 5000000 },
  { value: 10000000, step: 10000000 },
  { value: 20000000, step: 30000000 },
  { value: 50000000, step: 50000000 },
  { value: 100000000, step: 100000000 },
  { value: 500000000, step: 500000000 },
  { value: 1000000000, step: 1000000000 },
  { value: 5000000000, step: 5000000000 },
  { value: 10000000000, step: 90000000000 },
  { value: FLOAT_MAX },
];

export const MARKET_CAP_RANGE = [
  { value: MARKET_CAP_MIN, step: 1000000 }, // acts as min value
  { value: 1000000, step: 9000000 },
  { value: 10000000, step: 15000000 },
  { value: 25000000, step: 25000000 },
  { value: 50000000, step: 50000000 },
  { value: 100000000, step: 150000000 },
  { value: 250000000, step: 250000000 },
  { value: 500000000, step: 500000000 },
  { value: 1000000000, step: 1500000000 },
  { value: 2500000000, step: 2500000000 },
  { value: 5000000000, step: 5000000000 },
  { value: 10000000000, step: 10000000000 },
  { value: 100000000000, step: 100000000000 },
  { value: 1000000000000, step: 1000000000000 },
  { value: 5000000000000, step: 5000000000000 },
  { value: MARKET_CAP_MAX },
];

export const ATR_RANGE = [
  { value: ATR_MIN, step: 0.25 }, // acts as min value
  { value: 0.5, step: 0.5 },
  { value: 3, step: 2 },
  { value: 5, step: 2.5 },
  { value: 10, step: 5 },
  { value: 20, step: 10 },
  { value: 100, step: 900 },
  { value: ATR_MAX },
];

export const GAP_RANGE = [
  { value: GAP_MIN, step: 0.25 }, // acts as min value
  { value: 0.5, step: 0.5 },
  { value: 3, step: 2 },
  { value: 5, step: 2.5 },
  { value: 10, step: 5 },
  { value: 20, step: 10 },
  { value: 100, step: 900 },
  { value: GAP_MAX },
];

export const COUNT_RANGE = [
  { value: COUNT_MIN, step: 1 }, // acts as min value
  { value: 10, step: 2 },
  { value: 50, step: 5 },
  { value: 100, step: 10 },
  { value: 200, step: 800 },
  { value: COUNT_MAX },
];

export const NEWS_RECENCY_RANGE = [
  { value: 0, step: 1 }, // acts as min value
  { value: 12, step: 2 },
  { value: 24, step: 24 },
  { value: 120 },
];

export const VOICE_BUFFER_RANGE = [
  { value: 0, step: 0.5 }, // acts as min value
  { value: 5, step: 1 },
  { value: 10 },
];

export const HALT_TIME_FILTER_RANGE = [
  { value: 0, step: 1 }, // acts as min value
  { value: 12, step: 2 },
  { value: 24, step: 24 },
  { value: 168 },
];

export const SECTORS_FILTER = {
  "CONSUMER GOODS": 1,
  HEALTHCARE: 2,
  BIOTECH: 16,
  "BASIC INDUSTRIES": 3,
  ENERGY: 4,
  "PUBLIC UTILITIES": 5,
  COMMUNICATIONS: 6,
  "CONSUMER SVC": 7,
  "BUSINESS SVC": 8,
  TECHNOLOGY: 9,
  TRANSPORTATION: 10,
  "CAPITAL GOODS": 11,
  "CONSUMER DISC": 12,
  FINANCE: 13,
  SPAC: 14,
  "REAL ESTATE": 15,
  ADR: 81,
  ETF: 82,
  // "OTHER": 98,
  MISCELLANEOUS: 99,
};

export const DEFAULT_STREAM_SETTING = {
  industries: {
    "CONSUMER GOODS": true,
    HEALTHCARE: true,
    BIOTECH: true,
    "BASIC INDUSTRIES": true,
    ENERGY: true,
    "PUBLIC UTILITIES": true,
    COMMUNICATIONS: true,
    "CONSUMER SVC": true,
    "BUSINESS SVC": true,
    TECHNOLOGY: true,
    TRANSPORTATION: true,
    "CAPITAL GOODS": true,
    "CONSUMER DISC": true,
    FINANCE: true,
    SPAC: false,
    "REAL ESTATE": true,
    ADR: true,
    ETF: true,
    // "OTHER": true,
    MISCELLANEOUS: true,
  },
  price: { min: PRICE_MIN, max: PRICE_MAX },
  volume: { min: AVG_VOL_MIN, max: AVG_VOL_MAX },
  float: { min: FLOAT_MIN, max: FLOAT_MAX },
  atr: { min: ATR_MIN, max: ATR_MAX },
  gap: { min: GAP_MIN, max: GAP_MAX },
  count: { min: COUNT_MIN, max: COUNT_MAX },
  marketCap: { min: MARKET_CAP_MIN, max: MARKET_CAP_MAX },
  custom_view: [],
};

export const STREAM_CHANNEL_TYPE = {
  NONE: "none",
  FILTER: "filter",
  CUSTOM: "custom",
  FAV: "favorite",
  POPULAR: "popular",
};

export const STREAM_CHANNEL_MODE = {
  DEFAULT: "default",
  NEART1: "t1",
  NEART2: "t2",
};

export const STREAM_CHANNEL_MODE_LABEL = {
  DEFAULT: "Default",
  NEART1: "Near .10",
  NEART2: "Near 1%",
};

export const DEFAULT_STREAM_SETTING_LIST = [
  {
    channel: "stream1",
    title: "Stream 1",
    type: STREAM_CHANNEL_TYPE.FILTER,
    mode: STREAM_CHANNEL_MODE.DEFAULT,
    haltluld: { halt: true, luld: true },
    value: DEFAULT_STREAM_SETTING,
    color: "#c40000",
  },
  {
    channel: "stream2",
    title: "Stream 2",
    type: STREAM_CHANNEL_TYPE.CUSTOM,
    mode: STREAM_CHANNEL_MODE.DEFAULT,
    haltluld: { halt: true, luld: true },
    value: DEFAULT_STREAM_SETTING,
    color: null,
  },
];

export const DEFAULT_OPTIONS_MODE = "Icon";

export const DEFAULT_NEWS_CONFIG = {
  icon: true,
  recency: 8,
  hide_rf: false,
};

export const DEFAULT_HALT_CONFIG = {
  durationLimit: 1, // hour
  symbolLenLimit: true, // filter out 4+ length symbols
};

export const TIMEFRAME_FILTER = {
  "1min": "1 min",
  "5min": "5 min",
  "15min": "15 min",
  "30min": "30 min",
  "1hr": "1 hour",
  "2hr": "2 hour",
  "4hr": "4 hour",
  "1day": "1 day",
  "1wk": "1 week",
  "1mo": "1 month",
};

export const TIMEFRAME_TRANSFORM_MAP = {
  "1min": "1m",
  "5min": "5m",
  "15min": "15m",
  "30min": "30m",
  "1hr": "1h",
  "2hr": "2h",
  "4hr": "4h",
  "1day": "1d",
  "1wk": "1w",
  "1mo": "1mo",
};

export const DISCOVERY_DISPLAY_DOLLAR_CHANGE = "$";
export const DISCOVERY_DISPLAY_PERCENT_CHANGE = "%";

export const DEFAULT_DISCOVERY_COLUMNS = [
  {
    column: "price_dist",
    hidden: false,
    display: DISCOVERY_DISPLAY_PERCENT_CHANGE,
  },
  {
    column: "sparkline",
    hidden: false,
  },
  {
    column: "trend",
    hidden: false,
  },
  {
    column: "volume",
    hidden: false,
  },
  {
    column: "marketCap",
    hidden: false,
  },
  {
    column: "float",
    hidden: false,
  },
  {
    column: "momentum",
    hidden: false,
  },
  {
    column: "tradeCount",
    hidden: false,
  },
  {
    column: "uVol",
    hidden: false,
  },
  {
    column: "vWapDist",
    hidden: true,
  },
  {
    column: "moneyflow",
    hidden: false,
  },
  {
    column: "short_ratio",
    hidden: false,
  },
  {
    column: "squeeze",
    hidden: false,
  },
  {
    column: "atr",
    hidden: false,
    display: DISCOVERY_DISPLAY_PERCENT_CHANGE,
  },
  {
    column: "halt",
    hidden: false,
  },
  {
    column: "rs",
    hidden: true,
  },
  {
    column: "gap",
    hidden: false,
    display: DISCOVERY_DISPLAY_PERCENT_CHANGE,
  },
  {
    column: "actions",
    hidden: false,
  },
];

export const DEFAULT_DISCOVERY_SETTING_LIST = [
  {
    id: "discovery1",
    title: "Discovery 1",
    value: DEFAULT_DISCOVERY_COLUMNS,
  },
  {
    id: "discovery2",
    title: "Discovery 2",
    value: DEFAULT_DISCOVERY_COLUMNS,
  },
];

export const DISCOVERY_COLUMN_LABELS = {
  price_dist: "Last",
  volume: "Volume",
  marketCap: "Market Cap",
  float: "Float",
  momentum: "Momentum",
  tradeCount: "Trades",
  uVol: "UVol",
  vWapDist: "VWAPDist",
  moneyflow: "Money Flow",
  short_ratio: "Short Ratio",
  squeeze: "Squeeze",
  atr: "ATR",
  trend: "Trend",
  rs: "RelStr",
  gap: "Gap",
  halt: "Halt",
  actions: "Actions",
  sparkline: "Sparkline",
};

export const DEFAULT_DISCOVERY_SECTOR = "All";

export const SHOW_ALL_DISCOVERY_FILTER = "Default";

export const DEFAULT_DISCOVERY_FILTER = "BigCapMOMO";

export const DEFAULT_TIMEFRAME = "15min";
export const DEFAULT_DISCOVERY_TIMEFRAME = DEFAULT_TIMEFRAME;

export const DEFAULT_DISCOVERY_ALERT_POPUP = {
  symbol: "",
  vWAPDist: "",
};

export const DEFAULT_DISCOVERY_SORT = {
  sortBy: "price_dist",
  sortDirection: SortDirection.DESC,
};

export const DISCOVERY_FILTER_TEMP_PREFIX = "temp-";

export const DISCOVERY_SPARKLINE_UPDATE_INTERVAL = 30000;

export const DISCOVERY_REALTIME_UPDATE_INTERVAL = 1500;

export const CHART_DEFAULT_SYMBOL = "QQQ";
export const CHART_DEFAULT_TIMEFRAME = "15m";

export const CHART_POSITIVE_COLOR = "#00ff6e";
export const CHART_NEGATIVE_COLOR = "#fb0410";

export const CHART_UPDATE_INTERVAL = 6000;

export const DEFAULT_VOICE_BUFFER = 0;

export const VOICE_NOTI_LABEL = {
  primary_high: "Stream 1 Highs",
  primary_low: "Stream 1 Lows",
  alt_high: "Stream 2 Highs",
  alt_low: "Stream 2 Lows",
  popular: "Popular",
  fav: "Favorites",
  halt: "Halts",
  filtered: "Conditional",
  flow: "Flow",
};

export const VOICE_NOTI_VOICE = {
  voice6: "Ruth",
  voice1: "Matthew",
  voice2: "Salli",
  voice3: "Brian",
  voice4: "Emma",
  voice5: "Aria",
};

export const VOICE_NAME_MAP = {
  Ruth: "Natalie",
};

export const VOICE_NOTI_TYPE = {
  symbol: "SYMBOL",
  name: "NAME",
};

export const PLANID = {
  STANDARD: "plan_IIwGl8jhyA9Bqd",
  PRO: "plan_IIwFu1E5o53e3B",
  PRO_SEMI_ANNUAL: "plan_IIwFXxMwy3rXnW",
  PRO_SEMI_ANNUAL_NEW: "price_1I0X14AlsOvzObxugSbKwlp2",
  PRO_SEMI_ANNUAL_V1_NEW: "price_1Pwq6WAlsOvzObxuAFaEindf",

  NEW_STANDARD: "price_1KDiQnAlsOvzObxuP3uKhkcU",
  NEW_PRO: "price_1KDiRcAlsOvzObxuujUs2zWK",
  NEW_PROPLUS: "price_1KDiVnAlsOvzObxuFWzCSrdH",
  NEW_STANDARD_ANNUAL: "price_1KDiv0AlsOvzObxuGaydt3as",
  NEW_PRO_ANNUAL: "price_1KDiuDAlsOvzObxuA1yYI5bR",
  NEW_PROPLUS_ANNUAL: "price_1KDitWAlsOvzObxuAGXIkAE9",

  V1_NEW_STANDARD: "price_1NgHbMAlsOvzObxuNtikw52H",
  V1_NEW_PRO: "price_1NgHdIAlsOvzObxuefMlznUJ",
  V1_NEW_PROPLUS: "price_1NgHeYAlsOvzObxu9BvlROUo",
  V1_NEW_STANDARD_ANNUAL: "price_1NgHgQAlsOvzObxu0VrzS5No",
  V1_NEW_PRO_ANNUAL: "price_1NgHi8AlsOvzObxurPq2fC7n",
  V1_NEW_PROPLUS_ANNUAL: "price_1NgHkiAlsOvzObxuGoR1phGK",
};

export const isPro = (planId) =>
  planId == PLANID.PRO ||
  planId == PLANID.PRO_SEMI_ANNUAL ||
  planId == PLANID.PRO_SEMI_ANNUAL_NEW ||
  planId == PLANID.PRO_SEMI_ANNUAL_V1_NEW;
export const isProNew = (planId) =>
  planId == PLANID.NEW_PRO ||
  planId == PLANID.NEW_PRO_ANNUAL ||
  planId == PLANID.V1_NEW_PRO ||
  planId == PLANID.V1_NEW_PRO_ANNUAL;
export const isProPlusNew = (planId) =>
  planId == PLANID.NEW_PROPLUS ||
  planId == PLANID.NEW_PROPLUS_ANNUAL ||
  planId == PLANID.V1_NEW_PROPLUS ||
  planId == PLANID.V1_NEW_PROPLUS_ANNUAL;

export const isActiveSubscription = (subscription) => {
  return (
    subscription &&
    (subscription["status"] === "trialing" || subscription["status"] === "active") &&
    !subscription["ended_at"]
  );
};

export const hasSubscription = (subscription) => {
  if (!subscription) return false;
  if (subscription["status"] === "canceled") return false;
  if (subscription["status"] === "unpaid") return false;
  if (subscription["status"] === "incomplete_expired") return false;
  if (subscription["ended_at"]) return false;
  if (subscription["canceled_at"]) return false;
  return true;
};

export const SYMBOL_CONTEXT_MENU_ID = "symbol-context-menu";
export const ALERT_CONTEXT_MENU_ID = "alert-context-menu";
export const MONEYFLOW_CONTEXT_MENU_ID = "moneyflow-context-menu";
export const VOLUME_CONTEXT_MENU_ID = "volume-context-menu";
export const TRADE_COUNT_CONTEXT_MENU_ID = "trade-count-context-menu";
export const TREND_CONTEXT_MENU_ID = "trend-context-menu";

export const TEST_SYMBOLS = [
  "TEST",
  "ZJZZT",
  "ZOZZT",
  "ZRZZT",
  "ZVZZT",
  "ZWZZT",
  "ZXZZT",
  "ZAZZT",
  "ZBZX",
  "ZBZZT",
  "ZCZZT",
];

export const newsSource = {
  bloomberg: "Bloomberg",
  reuters: "Reuters",
  cnbc: "CNBC",
  "wall-street-journal": "Wall Street Journal",
  barrons: "Barrons",
  prNewswire: "PR Newswire",
  globenewswire: "Globe Newswire",
  businesswire: "BusinessWire",
  accesswire: "AccessWire",
  seekingAlpha: "SeekingAlpha",
  benzinga: "Benzinga",
  sandpGlobal: "S&P Global",
  clinicaltrials: "ClinicalTrials.gov",
  usSam: "SAM.gov",
  "sec-api": "SEC Filings",
  secPressReleases: "SEC Press Releases",
  fccFilings: "FCC Filings",
  uspto: "Patent Database (USPTO)",
  usptoTrialAndAppeal: "Patent Trial & Appeal Board",
  usDod: "Department of Defense",
  usFda: "FDA Drug Approvals",
  // "usFda": "FDA Press Releases",
  usEconomicIndicators: "Economic Indicators",
};

export const SQUEEZE_SORT = {
  NOW: "NOW",
  PRE_ASC: "PRE_ASC",
  PRE_DESC: "PRE_DESC",
  POST_ASC: "POST_ASC",
  POST_DESC: "POST_DESC",
};

export const SQUEEZE_SORT_LABEL = {
  NOW: "NOW",
  PRE_ASC: "PRE",
  PRE_DESC: "PRE",
  POST_ASC: "POST",
  POST_DESC: "POST",
};

export const SQUEEZE_SORT_NEXT = (current) => {
  const { NOW, PRE_ASC, PRE_DESC, POST_ASC, POST_DESC } = SQUEEZE_SORT;
  const arr = [NOW, PRE_ASC, PRE_DESC, POST_ASC, POST_DESC];
  const index = arr.lastIndexOf(current);
  if (index === -1) return NOW;
  return arr[(index + 1) % 5];
};

export const LAST_SORT = ["LAST_DESC", "LAST_ASC", "DOLLAR_DESC", "DOLLAR_ASC", "PERCENT_DESC", "PERCENT_ASC"];

export const LAST_SORT_NEXT = (current) => {
  const index = LAST_SORT.lastIndexOf(current);
  if (index === -1) return LAST_SORT[0];
  return LAST_SORT[(index + 1) % 6];
};

export const ALERT_TYPE_TITLE = {
  conditional: "Conditional",
  halts: "Halts",
  high_low: "High/Low",
  uvol: "Unusual Volume",
  vwapdist: "VWAP Dist",
  price: "Price",
};

export const TREND_TYPE = {
  BUY: "buy",
  SELL: "sell",
};

export const TREND_CHANGE_TYPE = {
  BUY_TO_SELL: "buy_to_sell",
  SELL_TO_BUY: "sell_to_buy",
};

export const LAST_AT_FILTER_UNIT = ["mins", "hours", "days"];

export const LAST_AT_FILTER_DEFAULT_THRESHOLD = 8;

export const DASHBOARD_LAYOUT_DEVICE_SIZES = {
  small: "(max-width: 767px)",
  medium: "(min-width: 768px) and (max-width: 991px)",
  large: "(min-width: 992px)",
};

export const DEFAULT_DASHBOARD_LAYOUT = {
  large: {
    flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
    stream1: { x: 0, y: 6, w: 8, h: 18, minW: 4, minH: 10 },
    stream2: { x: 0, y: 24, w: 8, h: 18, minW: 4, minH: 10 },
    popular: { x: 8, y: 6, w: 4, h: 6, minW: 2, minH: 5 },
    alerts: { x: 8, y: 12, w: 4, h: 6, minW: 2, minH: 5 },
    news: { x: 8, y: 18, w: 4, h: 6, minW: 2, minH: 5 },
    chart: { x: 8, y: 24, w: 4, h: 15, minW: 4, minH: 8 },
    chat: { x: 8, y: 39, w: 4, h: 15, minW: 4, minH: 8 },
    discovery1: { x: 0, y: 42, w: 8, h: 15, minW: 6, minH: 8 },
    discovery2: { x: 0, y: 57, w: 8, h: 15, minW: 6, minH: 8 },
  },
  medium: {
    flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
    stream1: { x: 0, y: 6, w: 12, h: 12, minW: 8, minH: 10 },
    stream2: { x: 0, y: 18, w: 12, h: 12, minW: 8, minH: 10 },
    chart: { x: 0, y: 51, w: 12, h: 12, minW: 6, minH: 8 },
    chat: { x: 0, y: 63, w: 12, h: 12, minW: 6, minH: 8 },
    popular: { x: 0, y: 30, w: 4, h: 6, minW: 3, minH: 4 },
    alerts: { x: 4, y: 30, w: 4, h: 6, minW: 3, minH: 4 },
    news: { x: 8, y: 30, w: 4, h: 6, minW: 3, minH: 4 },
    discovery1: { x: 0, y: 36, w: 12, h: 15, minW: 10, minH: 8 },
    discovery2: { x: 0, y: 75, w: 12, h: 15, minW: 10, minH: 8 },
  },
  small: {
    flow: { x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
    stream1: { x: 0, y: 4, w: 12, h: 12, minW: 12, minH: 10 },
    stream2: { x: 0, y: 5, w: 12, h: 12, minW: 12, minH: 10 },
    popular: { x: 0, y: 16, w: 12, h: 6, minW: 12, minH: 4 },
    alerts: { x: 0, y: 22, w: 12, h: 6, minW: 12, minH: 4 },
    news: { x: 0, y: 28, w: 12, h: 6, minW: 12, minH: 4 },
    chart: { x: 0, y: 34, w: 12, h: 10, minW: 12, minH: 8 },
    chat: { x: 0, y: 59, w: 12, h: 10, minW: 12, minH: 8 },
    discovery1: { x: 0, y: 44, w: 12, h: 15, minW: 12, minH: 8 },
    discovery2: { x: 0, y: 59, w: 12, h: 15, minW: 12, minH: 8 },
  },
};

export const DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM = {
  small: {
    flow: { x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
    stream1: { x: 0, y: 4, w: 12, h: 10, minW: 12, minH: 10 },
    stream2: { x: 0, y: 14, w: 12, h: 10, minW: 12, minH: 10 },
    popular: { x: 0, y: 24, w: 12, h: 6, minW: 12, minH: 4 },
    alerts: { x: 0, y: 30, w: 12, h: 6, minW: 12, minH: 4 },
    news: { x: 0, y: 36, w: 12, h: 6, minW: 12, minH: 4 },
    chart: { x: 0, y: 42, w: 12, h: 10, minW: 12, minH: 8 },
    chat: { x: 0, y: 67, w: 12, h: 10, minW: 12, minH: 8 },
    discovery1: { x: 0, y: 52, w: 12, h: 15, minW: 12, minH: 8 },
    discovery2: { x: 0, y: 67, w: 12, h: 15, minW: 12, minH: 8 },
  },
  medium: {
    flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
    stream1: { x: 0, y: 6, w: 8, h: 12, minW: 8, minH: 10 },
    stream2: { x: 0, y: 18, w: 8, h: 12, minW: 8, minH: 10 },
    popular: { x: 8, y: 6, w: 4, h: 8, minW: 3, minH: 4 },
    alerts: { x: 8, y: 14, w: 4, h: 8, minW: 3, minH: 4 },
    news: { x: 8, y: 22, w: 4, h: 8, minW: 3, minH: 4 },
    chart: { x: 0, y: 30, w: 12, h: 12, minW: 6, minH: 8 },
    chat: { x: 0, y: 57, w: 12, h: 12, minW: 6, minH: 8 },
    discovery1: { x: 0, y: 42, w: 12, h: 15, minW: 10, minH: 8 },
    discovery2: { x: 0, y: 57, w: 12, h: 15, minW: 10, minH: 8 },
  },
  large: {
    flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
    stream1: { x: 0, y: 6, w: 6, h: 18, minW: 4, minH: 10 },
    stream2: { x: 6, y: 6, w: 6, h: 18, minW: 4, minH: 10 },
    popular: { x: 0, y: 24, w: 4, h: 8, minW: 2, minH: 5 },
    alerts: { x: 4, y: 24, w: 4, h: 8, minW: 2, minH: 5 },
    news: { x: 8, y: 24, w: 4, h: 8, minW: 2, minH: 5 },
    chart: { x: 8, y: 32, w: 4, h: 15, minW: 4, minH: 8 },
    chat: { x: 8, y: 47, w: 4, h: 15, minW: 4, minH: 8 },
    discovery1: { x: 0, y: 32, w: 8, h: 15, minW: 6, minH: 8 },
    discovery2: { x: 0, y: 47, w: 8, h: 15, minW: 6, minH: 8 },
  },
};

export const DEFAULT_DASHBOARD_MENU = {
  flow: true,
  stream1: true,
  stream2: false,
  alerts: true,
  meters: true,
  popular: true,
  news: true,
  quotes: true,
  discovery1: true,
  discovery2: false,
  chart: false,
  chat: false,
};

export const DEFAULT_DASHBOARD_LAYOUT_CONFIG = [
  {
    key: "primary",
    label: "Layout 1",
    devices: {
      small: {
        layout: DEFAULT_DASHBOARD_LAYOUT.small,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      medium: {
        layout: DEFAULT_DASHBOARD_LAYOUT.medium,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      large: {
        layout: DEFAULT_DASHBOARD_LAYOUT.large,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
    },
    locked: true,
    active: true,
  },
  {
    key: "optional",
    label: "Layout 2",
    devices: {
      small: {
        layout: DEFAULT_DASHBOARD_LAYOUT.small,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      medium: {
        layout: DEFAULT_DASHBOARD_LAYOUT.medium,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      large: {
        layout: DEFAULT_DASHBOARD_LAYOUT.large,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
    },
    locked: true,
    active: false,
  },
  {
    key: "dynamic",
    label: "Layout 3",
    devices: {
      small: {
        layout: DEFAULT_DASHBOARD_LAYOUT.small,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      medium: {
        layout: DEFAULT_DASHBOARD_LAYOUT.medium,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
      large: {
        layout: DEFAULT_DASHBOARD_LAYOUT.large,
        menu: DEFAULT_DASHBOARD_MENU,
        popout: [],
      },
    },
    locked: false,
    active: false,
  },
];

export const DASHBOARD_WIDGET_TOOLBAR_COLLAPSE_TIMEOUT = 3500;

export const LAYOUT_BREAKPOINT_MEDIA_QUERIES = [
  "(min-width: 1400px)",
  "(max-width: 1399px) and (min-width: 1200px)",
  "(max-width: 1199px) and (min-width: 992px)",
  "(max-width: 991px) and (min-width: 768px)",
  "(max-width: 767px) and (min-width: 576px)",
  "(max-width: 575px)",
];

export const DEFAULT_SYMBOL_POPUP = {
  visible: false,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  symbol: "",
};

export const SEARCH_DROPDOWN_MODE_SYMBOL = "symbol";
export const SEARCH_DROPDOWN_MODE_USER = "user";

export const DEFAULT_SEARCH_DROPDOWN = {
  visible: false,
  mobileVisible: false,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  mode: "", // Search content: symbol/user
  mobileSearch: "",
  search: "",
  source: "", // component name i.e. news, discovery1, discovery2, chat
  clicked: {
    // clicked symbol/username && source from the search result
    symbol: "",
    source: "",
    extra: null,
  },
};

export const DEFAULT_MONEYFLOW_DATA = {
  symbol: "",
  dist: 0,
  loading: false,
  tf: "",
  data: [],
  err: "",
};

export const DEFAULT_VOLUME_DATA = {
  symbol: "",
  dist: 0,
  loading: false,
  tf: "",
  data: [],
  err: "",
};

export const DEFAULT_TRADECOUNT_DATA = {
  symbol: "",
  loading: false,
  tf: "",
  data: [],
  err: "",
};

export const DEFAULT_TREND_DATA = {
  symbol: "",
  trend: null,
  pricePoint: null,
  ROI: null,
};

export const WINDOWS_CHROME_X = 20;
export const WINDOWS_CHROME_Y = 75;

export const WINDOWS_CHROME_PWA_X = 16;
export const WINDOWS_CHROME_PWA_Y = 40;

export const WINDOWS_FIREFOX_X = 20;
export const WINDOWS_FIREFOX_Y = 100;

export const WINDOWS_EDGE_X = 20;
export const WINDOWS_EDGE_Y = 80;

export const WINDOWS_EDGE_PWA_X = 16;
export const WINDOWS_EDGE_PWA_Y = 40;

export const MAC_CHROME_X = 2;
export const MAC_CHROME_Y = 55;

export const MAC_CHROME_PWA_X = 2;
export const MAC_CHROME_PWA_Y = 40;

export const MAC_SAFARI_X = 0;
export const MAC_SAFARI_Y = 28;

export const MAC_FIREFOX_X = 0;
export const MAC_FIREFOX_Y = 69;

export const MAX_QUOTES_LIMIT = 75;
export const DEFAULT_QUOTE_CONFIG = {
  sortMethod: null,
};

export const FLOW_TIMEFRAME_FILTER = {
  "1min": "1 min",
  "5min": "5 min",
};

export const CHAT_FILES_LIMIT_CNT = 5;

export const CONDITIONAL_ALERT_MATCHING_COUNT_LIMIT = 2000;

export const CONDITIONAL_ALERT_STEP_CRITERIA_BASE = 1;
export const CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED = 2;
export const CONDITIONAL_ALERT_STEP_TRIGGER = 3;
export const CONDITIONAL_ALERT_STEP_RATE = 4;

export const CONDITIONAL_ALERT_STEPS = [
  {
    id: CONDITIONAL_ALERT_STEP_CRITERIA_BASE,
    label: "Step 1",
  },
  {
    id: CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED,
    label: "Step 2",
  },
  {
    id: CONDITIONAL_ALERT_STEP_TRIGGER,
    label: "Step 3",
  },
  {
    id: CONDITIONAL_ALERT_STEP_RATE,
    label: "Step 4",
  },
];

export const CONDITIONAL_ALERT_STEP_LABEL = {
  [CONDITIONAL_ALERT_STEP_CRITERIA_BASE]: "Base Criteria",
  [CONDITIONAL_ALERT_STEP_CRITERIA_ADVANCED]: "Advanced Criteria",
  [CONDITIONAL_ALERT_STEP_TRIGGER]: "Trigger Definition",
  [CONDITIONAL_ALERT_STEP_RATE]: "Rate of Change",
};

export const CONDITIONAL_ALERT_FIELD_TYPE_CRITERIA = "criteria";
export const CONDITIONAL_ALERT_FIELD_TYPE_TRIGGER = "trigger";

export const BASE_CRITERIA_COLUMNS = ["last", "marketCap", "category"];
export const ADVANCED_CRITERIA_COLUMNS = [
  "atr",
  "avgVolume",
  "float",
  "gap",
  "halt",
  "mf_price",
  "news",
  "rs",
  "short_ratio",
  "squeeze",
  "tradeCount",
  "trend",
  "rv_price",
  "vWapDist",
];
export const TRIGGER_COLUMNS = [
  "atr",
  "price_dist",
  "momentum",
  "mf_price",
  "mf_dist",
  "rs",
  "trend_change",
  "uVol",
  "tradeCount",
  "tradeCount_dist",
  "rv_price",
  "rv_dist",
  "vWapDist",
];
